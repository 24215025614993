import { useI18n } from 'vue-i18n'
import { appsignal } from '@/modules/base/composable/useAppsignal'
import { useToastNotification } from '@/modules/base/composable/useToastNotification'

export const useDefaultMutationHandler = (
  showSuccessToast = true,
  successKey?: string,
  errorKey?: string
) => {
  const { t } = useI18n()
  const { pushSuccessToast, pushErrorToast } = useToastNotification()
  return {
    defaultMutationHandler: {
      onSuccess: () => {
        if (showSuccessToast) {
          pushSuccessToast(t(successKey ?? 'global.toastMessages.changesSaved'))
        }
      },
      onError: (error: Error) => {
        pushErrorToast(t(errorKey ?? 'global.toastMessages.unableToSave'))
        appsignal.send(error)
      },
    },
  }
}
